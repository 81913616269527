import dayjs, { ManipulateType } from 'dayjs';
import {
  Booking,
  PaymentMethod,
  PaymentStatus,
  Role,
  Source,
  User,
} from 'types';

export const USER_ROLES = {
  li3ibRoles: [
    Role.SuperAdmin,
    Role.Li3ibAdmin,
    Role.CallCenterOperative,
    Role.Li3ibAccountant,
  ],
  walletRoles: [Role.SuperAdmin, Role.Li3ibAccountant],
  dashboardRoles: [Role.SuperAdmin, Role.Li3ibAccountant],
  sponserRoles: [Role.SuperAdmin],

  withdrawRolesAccount: [Role.Li3ibAccountant],
  withdrawRolesSuperAdmin: [Role.SuperAdmin],
  packageReportRoles: [Role.SuperAdmin, Role.Li3ibAccountant, Role.Accountant],
  WalletReportRoles: [Role.SuperAdmin, Role.Li3ibAccountant],
  CustomerWalletReportRoles: [Role.SuperAdmin, Role.Li3ibAccountant],
  companyViewRoles: [Role.SuperAdmin],
  clientRoles: [Role.ClientAdmin, Role.Coordinator, Role.Accountant],
  notificationRole: [Role.SuperAdmin],
  adminPortalRoles: [
    Role.SuperAdmin,
    Role.Li3ibAdmin,
    Role.CallCenterOperative,
    Role.Li3ibAccountant,
    Role.ClientAdmin,
    Role.Coordinator,
    Role.Accountant,
  ],

  dayViewStatsLi3ibRoles: [
    Role.SuperAdmin,
    Role.Li3ibAdmin,
    Role.Li3ibAccountant,
  ],
  dayViewStatsClientRoles: [
    Role.SuperAdmin,
    Role.Li3ibAdmin,
    Role.Li3ibAccountant,
    Role.ClientAdmin,
    Role.Accountant,
  ],
  packageRoles: [
    Role.SuperAdmin,
    // Role.Li3ibAdmin,
    Role.ClientAdmin,
    Role.Li3ibAccountant,
    Role.Coordinator,
    Role.Accountant,
    Role.CallCenterOperative,
  ],
  packageRolesCancellation: [
    Role.SuperAdmin,
    Role.Li3ibAdmin,
    Role.Li3ibAccountant,
    // Role.Accountant,
    // Role.CallCenterOperative,
    Role.Li3ibAccountant,
  ],
  packagemaster: [
    Role.SuperAdmin,
    Role.Li3ibAdmin,
    Role.Li3ibAccountant,
    // Role.Accountant,
    // Role.CallCenterOperative,
    // Role.Li3ibAccountant,
  ],
  packageCooridntor: [
    Role.SuperAdmin,
    Role.Li3ibAdmin,
    Role.Li3ibAccountant,
    Role.Accountant,
    Role.CallCenterOperative,
    Role.Coordinator,
    Role.Li3ibAccountant,
  ],
  companyInfoTermrRoles: [Role.SuperAdmin],
  all: Object.values(Role),
};

export const hasDayViewStatsLi3ibRoles = (user: User) =>
  USER_ROLES.dayViewStatsLi3ibRoles.includes(user.role);

export const hasDayViewStatsClientRoles = (user: User) =>
  USER_ROLES.dayViewStatsClientRoles.includes(user.role);

export const hasLi3ibRole = (user: User) =>
  USER_ROLES.li3ibRoles.includes(user.role);

export const hasPackageReRole = (user: User) =>
  USER_ROLES.packageReportRoles.includes(user.role);

export const hasWalletReRole = (user: User) =>
  USER_ROLES.WalletReportRoles.includes(user.role);

export const hasCustomerWalletReRole = (user: User) =>
  USER_ROLES.CustomerWalletReportRoles.includes(user.role);

export const hasWalletRole = (user: User) =>
  USER_ROLES.walletRoles.includes(user.role);
export const hasNotificationRole = (user: User) =>
  USER_ROLES.notificationRole.includes(user.role);
export const hasWithdrawApproveRole = (user: User) =>
  USER_ROLES.withdrawRolesAccount.includes(user.role);

export const hasWithdrawSuperadminRole = (user: User) =>
  USER_ROLES.withdrawRolesSuperAdmin.includes(user.role);

export const hasCompanyViewRole = (user: User) =>
  USER_ROLES.companyViewRoles.includes(user.role);

export const hasClientRole = (user: User) =>
  USER_ROLES.clientRoles.includes(user.role);

export const hasDashBoadRole = (user: User) =>
  USER_ROLES.dashboardRoles.includes(user.role);

export const hasSponserRole = (user: User) =>
  USER_ROLES.sponserRoles.includes(user.role);

export const allAvailableRolesExceptUser = (user: User) => {
  if (user.role === Role.Li3ibAdmin) {
    return USER_ROLES.all.filter(
      (role) => ![Role.User, Role.SuperAdmin].includes(role),
    );
  }

  return USER_ROLES.all.filter((role) => role !== Role.User);
};

export const allAvailableRolesForLoggedInUser = (user: User) => {
  if (user.role === Role.Li3ibAdmin) {
    return USER_ROLES.all.filter((role) => ![Role.SuperAdmin].includes(role));
  }

  return USER_ROLES.all;
};
export const haspackageRoles = (user: User) =>
  USER_ROLES.packageRoles.includes(user.role);
export const hascompanyInfoTerm = (user: User) =>
  USER_ROLES.companyInfoTermrRoles.includes(user.role);
export const haspackageCancellation = (user: User) =>
  USER_ROLES.packageRolesCancellation.includes(user.role);
export const haspackageMaster = (user: User) =>
  USER_ROLES.packagemaster.includes(user.role);
export const haspackageCooridantor = (user: User) =>
  USER_ROLES.packageCooridntor.includes(user.role);
const isGlobalAdmin = (user: User) =>
  user.role === Role.SuperAdmin || user.role === Role.Li3ibAdmin;
const isManagingRole = (user: User) => user.role === Role.SuperAdmin;
// || user.role === Role.Li3ibAdmin
// || user.role === Role.CallCenterOperative;

const isAdminPortalUser = (user: User) =>
  USER_ROLES.adminPortalRoles.includes(user.role);
const isAccountantRole = (user: User) =>
  user.role === Role.SuperAdmin ||
  // user.role === Role.Li3ibAdmin ||
  user.role === Role.Li3ibAccountant ||
  user.role === Role.ClientAdmin ||
  user.role === Role.Accountant;

const isOnlyLi3ibAccountantRole = (user: User) =>
  user.role === Role.Li3ibAccountant;

export type Permissions = ReturnType<typeof getUserPermissions>;
export type PermissionKey = NestedKeyOf<Permissions>;

export const getUserPermissions = (user: User) => {
  return {
    company: {
      add: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
      select: hasLi3ibRole(user),
      view: hasCompanyViewRole(user),
    },
    companyType: {
      select: hasLi3ibRole(user),
    },
    facility: {
      select: isAdminPortalUser(user),
    },
    facilities: {
      view: hasLi3ibRole(user),
      create: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
    },
    fields: {
      view: hasLi3ibRole(user),
      add: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
    },
    source: {
      view: hasLi3ibRole(user),
    },
    community: {
      view: isManagingRole(user),
      edit: isManagingRole(user),
    },
    notification: {
      view: hasNotificationRole(user),
      edit: hasNotificationRole(user),
    },
    social: {
      view: isManagingRole(user),
      edit: isManagingRole(user),
    },
    userLevel: {
      view: isManagingRole(user),
      edit: isManagingRole(user),
    },
    users: {
      view: isManagingRole(user),
      edit: isManagingRole(user),
    },
    amenities: {
      view: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
    },
    sports: {
      view: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
    },
    adminUsers: {
      view: isGlobalAdmin(user),
      edit: isGlobalAdmin(user),
    },
    withdraw: {
      view: hasWalletRole(user),
    },
    withdrawapprove: {
      view: hasWithdrawApproveRole(user),
    },
    withdrawSuperadmin: {
      view: hasWithdrawSuperadminRole(user),
    },
    wallet: {
      view: hasWalletRole(user),
    },
    package: {
      view: haspackageRoles(user),
      edit: haspackageRoles(user),
    },
    reports: {
      view: isAccountantRole(user),
    },

    allWalletReports: {
      view: isOnlyLi3ibAccountantRole(user),
    },
    companyinfoterm: {
      view: hascompanyInfoTerm(user),
    },
    cancellationPackage: {
      view: haspackageCancellation(user),
    },
    packageMaster: {
      view: haspackageMaster(user),
    },
    dashboardView: {
      view: hasDashBoadRole(user),
    },
    sponserView: {
      view: hasSponserRole(user),
      edit: hasSponserRole(user),
    },
  };
};

/** Booking view and edit permissions */
// const isBeforeBookingStart = (booking: Booking) =>
//   dayjs(new Date()).isBefore(booking.start_date);

const isBeforeBookingEnd = (booking: Booking) =>
  dayjs(new Date()).isBefore(booking.end_date);

const isAfterBookingStartWithExtraTime = (
  booking: Booking,
  extraAmount: number,
  unit: ManipulateType,
) =>
  dayjs(new Date()).isAfter(
    dayjs(booking.start_date).subtract(extraAmount, unit),
  );

const isBeforeBookingEndWithExtraTime = (
  booking: Booking,
  extraAmount: number,
  unit: ManipulateType,
) => dayjs(new Date()).isBefore(dayjs(booking.end_date).add(extraAmount, unit));

const isAllowedAddAndCancelPastBooking = (date: any) => {
  // Get the current date and time
  const bookingDate = new Date(date);

  // Get the current date and time
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds =
    currentDate.getTime() - bookingDate.getTime();

  // Convert milliseconds to hours
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

  // console.log(differenceInHours);

  // Check if the difference is exactly 24 hours
  return differenceInHours <= 24;
};

const canEditAllSourceBookings = (user: User) =>
  // [Role.SuperAdmin, Role.Li3ibAdmin, Role.CallCenterOperative].includes(
  //   user.role,
  // );
  [Role.SuperAdmin, Role.Li3ibAdmin, Role.CallCenterOperative].includes(
    user.role,
  );

const hasAddOrEditPastBookings = (user: User) =>
  [
    Role.SuperAdmin,
    Role.Li3ibAdmin,
    Role.ClientAdmin,
    Role.CallCenterOperative,
  ].includes(user.role);

export const canAddOrCancelPastBookings = (user: User, date: any) => {
  const hasRole = hasAddOrEditPastBookings(user);

  return isAllowedAddAndCancelPastBooking(date) && hasRole;
};

const canEditClientSourceBooking = (user: User, booking: Booking) =>
  [Role.ClientAdmin].includes(user.role) &&
  (booking.source === Source.ExternalAdminPortal ||
    booking.source === Source.InternalAdminPortal);

const canEditConfirmations = (user: User) =>
  [Role.ClientAdmin, Role.Coordinator].includes(user.role);

export const canViewPhoneNumber = (user: User, booking: Booking) =>
  canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking);

const canEditPhoneNumber = (user: User, booking: Booking) =>
  isBeforeBookingEnd(booking) &&
  (canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking));

const canEditName = (user: User, booking: Booking) =>
  isBeforeBookingEnd(booking) &&
  (canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking));

const canEditDiscount = (user: User, booking: Booking) =>
  isBeforeBookingEndWithExtraTime(booking, 30, 'minutes') &&
  (canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking));

export const canCancelBooking = (user: User, booking: Booking) =>
  // isBeforeBookingStart(booking) &&
  canAddOrCancelPastBookings(user, booking.start_date) &&
  !booking.is_cancelled &&
  (canEditAllSourceBookings(user) || canEditClientSourceBooking(user, booking));

export const canViewBookingAuthor = (user: User) => hasLi3ibRole(user);
export const canViewCompany = (user: User) => hasLi3ibRole(user);

const canEditPresence = (user: User, booking: Booking) => {
  if (booking.client_showed_up !== null) return false;

  return (
    isAfterBookingStartWithExtraTime(booking, 30, 'minutes') &&
    isBeforeBookingEndWithExtraTime(
      booking,
      Role.Coordinator == user.role ? 30 : 1440,
      'minutes',
    ) &&
    (canEditAllSourceBookings(user) || canEditConfirmations(user))
  );
};

const canEditPayment = (user: User, booking: Booking) => {
  if (!booking?.transaction) return false;

  if (booking?.transaction?.payment_status !== PaymentStatus?.Created)
    return false;

  const hasRole = canEditAllSourceBookings(user) || canEditConfirmations(user);

  if (booking.transaction.payment_method === PaymentMethod.Cash) {
    return (
      isAfterBookingStartWithExtraTime(booking, 30, 'minutes') &&
      isBeforeBookingEndWithExtraTime(
        booking,
        Role.Coordinator == user.role ? 30 : 1440,
        'minutes',
      ) &&
      hasRole
    );
  }

  return isBeforeBookingEndWithExtraTime(booking, 30, 'minutes') && hasRole;
};

export const canEditBooking = (user: User, booking: Booking) => {
  if (booking.is_cancelled)
    return {
      phoneNumber: false,
      firstName: false,
      lastName: false,
      discount: false,
      payment: false,
      presence: false,
    };

  return {
    phoneNumber: canEditPhoneNumber(user, booking),
    firstName: canEditName(user, booking),
    lastName: canEditName(user, booking),
    discount: canEditDiscount(user, booking),
    payment: canEditPayment(user, booking),
    presence:
      user?.role == 'SuperAdmin' ? true : canEditPresence(user, booking),
  };
};

export const canEditCompany = (user: User) => {
  const canEditCompanyPermission = getUserPermissions(user).company.edit;

  return {
    name: canEditCompanyPermission,
    name_arabic: canEditCompanyPermission,
    address: canEditCompanyPermission,
    country: canEditCompanyPermission,
    phone_number: canEditCompanyPermission,
    email: canEditCompanyPermission,
    geolocation: canEditCompanyPermission,
    type: canEditCompanyPermission,
  };
};

export const canCreateFacility = (user?: User) => {
  if (!user) return false;

  return getUserPermissions(user).facilities.create;
};

export const canEditField = (user?: User) => {
  if (!user) return false;

  return getUserPermissions(user).fields.edit;
};

export const canDownloadAccountsReport = (user?: User) => {
  if (!user) return false;

  return hasLi3ibRole(user);
};
export const canpackageReportRoles = (user?: User) => {
  if (!user) return false;

  return hasPackageReRole(user);
};
export const canWalletReportRoles = (user?: User) => {
  if (!user) return false;

  return hasWalletReRole(user);
};
export const canWalletCustomerReportRoles = (user?: User) => {
  if (!user) return false;

  return hasCustomerWalletReRole(user);
};

export const canViewDayViewLi3ibStats = (user?: User) => {
  if (!user) return false;

  return hasDayViewStatsLi3ibRoles(user);
};

export const canViewDayViewClientStats = (user?: User) => {
  if (!user) return false;

  return hasDayViewStatsClientRoles(user);
};
