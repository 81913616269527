import { EditNotificationModal } from 'features/notification/components/modal/edit-items-modal-notifications';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
export const NotificationEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.NOTIFICATION);
    document.body.style.overflow = 'visible';
  };

  return (
    <EditNotificationModal
      id={id}
      onClose={closeModal}
      onSuccess={closeModal}
    />
  );
};
