import { Form } from 'antd';
import { ContentFilters } from 'components';
import { SponserFilterForm } from 'features/sponsers/component/filter-form/sponser-filter-form/sponsers-filter-form';
import {
  SponserFilterFormPaginated,
  SponserFilterFormValues,
} from 'features/sponsers/component/filter-form/sponser-filter-form/type';
import { SponserTable } from 'features/sponsers/component/sponser-table/sponser-table';
import { SponserTopBar } from 'features/sponsers/component/top-bar/sponser-top';
import { useGetSponserList } from 'features/sponsers/use-cases/get-sponser-list';
import { usePaginationParams } from 'hooks';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const initialFilterValues: SponserFilterFormValues = {
  name: undefined,
  is_show_on_app: undefined,
  is_active: undefined,
};

export const SponserListView = () => {
  const { params, updateParams, resetPage } =
    usePaginationParams<SponserFilterFormPaginated>({
      page: 1,
      size: 10,
      ...initialFilterValues,
    });

  const { data, isFetching, refetch } = useGetSponserList(params);

  const handleFiltersChange = (values: SponserFilterFormValues) => {
    updateParams(values);
    resetPage();
  };
  const formId = 'filter-sponser-form';
  const [form] = Form.useForm<SponserFilterFormValues>();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/sponser') {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [location]);

  return (
    <div>
      <SponserTopBar
        refetch={refetch}
        form={form}
        resetPage={resetPage}
        handleFilter={handleFiltersChange}
        initialValues={initialFilterValues}
      />
      <ContentFilters formId={formId}>
        <SponserFilterForm
          form={form}
          formId={formId}
          handleFilter={handleFiltersChange as any}
          initialValues={initialFilterValues as any}
        />
      </ContentFilters>
      <SponserTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
      />

      <Outlet />
    </div>
  );
};
