import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { usePermissions } from 'features/users';
import { UsersWithWithdrawFilterFormPaginated } from 'features/withdraw/components/users-with-wallet-filter-form/type';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { UserWithithdraw } from 'types/withdraw';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';

type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: UsersWithWithdrawFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<UsersWithWithdrawFilterFormPaginated>>;
};

export const SuperAdminTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  // const screens = useBreakpoint();
  const navigate = useNavigate();
  const permissions = usePermissions();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserWithithdraw> | SorterResult<UserWithithdraw>[],
  ) => {
    const sortBy = () => {
      if (Array.isArray(sorter)) return;

      const sortOrder =
        sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`;

      if (sorter?.field === 'last_transaction_date') {
        return {
          order_by_balance: undefined,
          order_by_transaction: sortOrder,
        };
      }
    };

    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      ...sortBy(),
    });
  };
  const handleValue = (matchId: any) => {
    navigate(getRoute(ROUTES.SUPER_ADMIN_APPROVE, matchId));
  };
  const handleValueReject = (matchId: any) => {
    navigate(getRoute(ROUTES.SUPER_ADMIN_REJECT, matchId));
  };

  const items = (record: any): MenuProps['items'] => {
    return [
      record.status_id == 2
        ? {
            label: 'Approved',
            onClick: () => handleValue(record.id),
            key: '0',
          }
        : null,
      record.status_id == 2
        ? {
            label: 'Reject',
            onClick: () => handleValueReject(record.id),
            key: '1',
          }
        : null,
      {
        label: 'View',
        onClick: () => navigate(getRoute(ROUTES.SUPER_DETAIL, record.id)),
        key: '2',
      },
    ];
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge text={'Total'} count={data?.total || 0} />
      </StyledTableHeader>
      <Table<UserWithithdraw>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        // onRow={(record: any) => {
        //   return {
        //     className: record.status_id !== 2 ? 'clickable-row' : '',
        //     onClick: () => {
        //       if (record.status_id !== 2) {
        //         navigate(getRoute(ROUTES.SUPER_DETAIL, record.id));
        //       }
        //     },
        //   };
        // }}
      >
        <Column
          title={t('wallet.table.userName')}
          dataIndex={'user_name'}
          // width={screens.xxl ? 'auto' : 170}
          render={(_, record: any) => record.user_name}
        />
        <Column<UserWithithdraw>
          title={t('wallet.table.phone')}
          dataIndex={'phone_number'}
          // width={screens.xxl ? 'auto' : 170}
        />
        <Column<UserWithithdraw>
          title={t('withdraw.table.amount')}
          dataIndex={'amount'}
          // sorter={true}
          // sortDirections={['descend', 'ascend', 'descend']} // workaround to remove null as an option
          // width={screens.xxl ? 'auto' : 130}
          render={(_, record: any) => (
            <span>{`${record.amount ? `${record.amount} KD` : ''}`}</span>
          )}
        />
        <Column<UserWithithdraw>
          title={t('withdraw.table.Date')}
          dataIndex={'request_date'}
          render={(_, record: any) => (
            <>
              {formatDate({
                date: record.request_date,
                format: 'date',
                timeZone: DEFAULT_TIMEZONE,
              } as any)}
              <Space style={{ padding: '2px' }}>
                {formatDate({
                  date: record.request_date,
                  format: 'time',
                  timeZone: DEFAULT_TIMEZONE,
                } as any)}
              </Space>
            </>
          )}
          // width={screens.xxl ? 'auto' : 130}
        />
        <Column<UserWithithdraw>
          title={t('withdraw.table.Status')}
          dataIndex={'status_name'}
          // width={screens.xxl ? 'auto' : 100}
          render={(_, record: any) => (
            <>
              <span>{`${record.status_name}`}</span>
            </>
          )}
        />
        {/* <Column<UserWithithdraw>
          title={t('withdraw.table.ReferenceNumber')}
          dataIndex={'refrence_no'}
        /> */}
        <Column<UserWithithdraw>
          title={t('withdraw.table.action')}
          dataIndex={'last_transaction_date'}
          render={(_, record: any) => (
            <Space>
              {/* {record.status_id == 2 ? ( */}
              <Dropdown menu={{ items: items(record) }} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space className='dot_icon_drop'>
                    <MoreOutlined style={{ fontWeight: '600' }} />
                  </Space>
                </a>
              </Dropdown>
              {/* ) : (
                <span>No actions</span>
              )} */}
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
