import { DownloadOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, DatePicker, Typography } from 'antd';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { canWalletReportRoles, useUserContext } from 'features/users';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';

import {
  CardWrapper,
  // ContentHeaderWrapper,
  StyledAvatar,
  StyledButtonWrapper,
  StyledCard,
  StyledText,
} from './reports.styles';

type ReportProps = {
  handleWalletReport: () => void;
  handleDate: any;
  loading: boolean;
};

export const Reports = ({
  handleWalletReport,
  handleDate,
  loading,
}: ReportProps) => {
  const { user } = useUserContext();
  const { t } = useTranslation();

  return (
    <>
      <StyledContentBackground>
        <div style={{ marginTop: -57, padding: 5, marginBottom: 30 }}>
          <div style={{ marginBottom: 10 }}>
            Please select date to generate the report.
          </div>
          <div>
            <DatePicker
              allowClear={true}
              format={'DD/MM/YYYY'}
              size='large'
              onChange={handleDate}
            />
          </div>
        </div>
        <CardWrapper className='crd_list'>
          {canWalletReportRoles(user) ? (
            <StyledCard>
              <StyledAvatar
                icon={
                  <FileTextOutlined style={{ color: colors.colorPrimary }} />
                }
              />
              <StyledText>Wallet Report</StyledText>
              <StyledButtonWrapper className='reprt_btn'>
                <Typography.Text strong style={{ fontSize: '1.2rem' }}>
                  {t('reports.xlsFile')}
                </Typography.Text>
                <Button
                  onClick={handleWalletReport}
                  loading={loading}
                  type='primary'
                  icon={<DownloadOutlined />}
                >
                  {t('reports.get')}
                </Button>
              </StyledButtonWrapper>
            </StyledCard>
          ) : null}
        </CardWrapper>
      </StyledContentBackground>
    </>
  );
};
