import { EditSponserModal } from 'features/sponsers/component/modal/edit-sponser-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const EditSponserEditView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.SPONSER_LIST);
    document.body.style.overflow = 'visible';
  };

  return (
    <EditSponserModal id={id} onClose={closeModal} onSuccess={closeModal} />
  );
};
