import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SponserFilterFormField, SponserFilterFormValues } from './type';

const { useBreakpoint } = Grid;

type UsersWithWalletFilterFormProps = {
  formId: string;
  handleFilter: (values: SponserFilterFormValues) => void;
  initialValues: SponserFilterFormValues;
  closeModal?: () => void;
  form: any;
};

export const SponserFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
  form,
}: UsersWithWalletFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  // const [form] = Form.useForm<SponserFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues });
  };

  const handleOnFinish = (values: SponserFilterFormValues) => {
    handleFilter(values);
    // setQueryFilters(values);
    closeModal?.();
  };

  return (
    <>
      <div className='notify_form'>
        <Form<SponserFilterFormValues>
          id={formId}
          name={formId}
          form={form}
          layout='vertical'
          onFinish={handleOnFinish}
          initialValues={initialValues}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
        >
          <ContentFilters.FormItemsWrapper>
            <StandardItemsWrapper>
              <Form.Item<SponserFilterFormValues>
                label={'Name'}
                name={SponserFilterFormField.Name}
              >
                <Input placeholder='Name' />
              </Form.Item>

              <Form.Item<SponserFilterFormValues>
                label={'Status'}
                name={SponserFilterFormField.IsActive}
              >
                <Select
                  style={{ width: 120 }}
                  options={[
                    { value: true, label: 'Active' },
                    { value: false, label: 'InActive' },
                  ]}
                  placeholder='Select Status'
                />
              </Form.Item>

              <Form.Item<SponserFilterFormValues>
                label={'Show On App'}
                name={SponserFilterFormField.IsShowOnApp}
              >
                <Select
                  style={{ width: 120 }}
                  options={[
                    { value: true, label: 'On' },
                    { value: false, label: 'Off' },
                  ]}
                  placeholder='Select Show On App'
                />
              </Form.Item>
            </StandardItemsWrapper>
          </ContentFilters.FormItemsWrapper>

          {screens.lg && (
            <ContentFilters.ButtonsWrapper>
              <Button type='primary' htmlType='submit'>
                {t('wallet.buttons.filter', { count: 1 })}
              </Button>
              <Button onClick={onReset}>{t('wallet.buttons.reset')}</Button>
            </ContentFilters.ButtonsWrapper>
          )}
        </Form>
      </div>
    </>
  );
};
