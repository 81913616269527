import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Image,
  Space,
  Switch,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { SponserFilterFormPaginated } from 'features/sponsers/component/filter-form/sponser-filter-form/type';
import { useUpdateStatusSponser } from 'features/sponsers/use-cases/status-update-sponser';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Notifications } from 'types/notification';
type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: SponserFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<SponserFilterFormPaginated>>;
  refetch: () => void;
};

export const SponserTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: UsersWithWalletTableProps) => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const handleTableChange = (pagination: TablePaginationConfig) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  const handleEdit = (record: any) => {
    navigate(getRoute(ROUTES.SPONSER_EDIT, record.id)); // Navigate to edit route
    document.body.style.overflow = 'hidden';
  };

  const { mutate } = useUpdateStatusSponser();

  const handleUpdateStatus = (id: number, checked: boolean) => {
    mutate({ id: id, Flag: 'ChangeStatus', is_active: checked });
  };

  const handleUpdateShowOnApp = (id: number, checked: boolean) => {
    mutate({ id: id, Flag: 'ChangeShow', is_show_on_app: checked });
  };

  if (!permissions) return null;

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge text={'Sponsor Found'} count={data?.total || 0} />
      </StyledTableHeader>
      <Table<Notifications>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
      >
        <Column<Notifications>
          title={'Name'}
          dataIndex={'name'}
          // width={screens.xxl ? 'auto' : 170}
        />

        <Column<Notifications>
          title={'Name Ar'}
          dataIndex={'name_arabic'}
          // width={screens.xxl ? 'auto' : 170}
        />

        <Column<Notifications>
          width={200}
          title={'Image'}
          dataIndex={'image'}
          render={(_, record: any) => (
            <>
              <Image src={record.image} fallback='' width={40} height={40} />
            </>
          )}
        />

        <Column<Notifications>
          // width={80}
          title={'Status'}
          dataIndex={'last_transaction_date'}
          // className='action_col'
          render={(_, record: any) => (
            <Space>
              <Tooltip title='Is Active'>
                <Switch
                  defaultChecked={record.is_active}
                  checked={record.is_active}
                  onChange={(isChecked) => {
                    handleUpdateStatus(record.id, isChecked);
                  }}
                />
              </Tooltip>
            </Space>
          )}
        />

        <Column<Notifications>
          // width={80}
          title={'Show On App'}
          dataIndex={'last_transaction_date'}
          render={(_, record: any) => (
            <Space>
              <Tooltip title='Is Active'>
                <Switch
                  defaultChecked={record.is_show_on_app}
                  checked={record.is_show_on_app}
                  onChange={(isChecked) => {
                    handleUpdateShowOnApp(record.id, isChecked);
                  }}
                />
              </Tooltip>
            </Space>
          )}
        />

        <Column<Notifications>
          // width={80}
          title={'Action'}
          render={(_, record: any) => (
            <Button onClick={() => handleEdit(record)} icon={<EditOutlined />}>
              Edit
            </Button>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
