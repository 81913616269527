import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { SponserApiService } from 'features/sponsers/api/sponser-api.service';
import { SponserQueryKeys } from 'features/sponsers/use-cases/query.keys';

const { createSponser } = SponserApiService();

export const useUpdateSponser = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: FormData) => {
      return createSponser(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: SponserQueryKeys.all,
        });
        if (data?.ResponseStatus == 1) {
          message.success('Sponsor Update successfully');
        } else {
          message.error(data?.ResponseMessage);
        }
      },
    },
  );
};
