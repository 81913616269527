import { PaginatedRequestParams } from 'api';

export enum SponserFilterFormField {
  Name = 'name',
  IsActive = 'is_active',
  IsShowOnApp = 'is_show_on_app',
}
export type SponserFilterFormValues = {
  [SponserFilterFormField.Name]: string | undefined;
  [SponserFilterFormField.IsActive]: boolean | undefined;
  [SponserFilterFormField.IsShowOnApp]: boolean | undefined;
};

export type SponserFilterFormPaginated = SponserFilterFormValues &
  PaginatedRequestParams;
