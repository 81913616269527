import { App } from 'antd';
import { handleError } from 'api';
import { AxiosResponse } from 'axios';
import { GetWalletReportDTO, WalletApiService } from 'features/wallet';
import i18n from 'locales/i18n';

const { getReportWalletList } = WalletApiService();

export const useGetReportWalletList = () => {
  const { message } = App.useApp();

  const downloadWalletReport = async (payload: GetWalletReportDTO) => {
    await getReportWalletList(payload)
      .then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `wallet_report_till_${payload.to_date}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        message.success(i18n.t('reports.successfulDownload'));
      })
      .catch((error) => {
        const uiMessage = handleError(error);
        console.error(uiMessage);

        message.warning(uiMessage);
      });
  };

  return {
    downloadWalletReport,
  };
};
