import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import { FacilitiesFilterForm } from 'features/facilities/components/facilities-filter-form/facilities-filter-form';
import {
  FacilitiesFilterFormField,
  FacilitiesFilterFormPaginated,
  FacilitiesFilterFormValues,
} from 'features/facilities/components/facilities-filter-form/types';
import { FacilitiesTable } from 'features/facilities/components/facilities-table/facilities-table';
import { useGetFacilities } from 'features/facilities/use-cases';
import { canCreateFacility, useUserContext } from 'features/users';
import { usePaginationParams } from 'hooks';
import { t } from 'i18next';
import { Outlet } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const initialFilterValues: FacilitiesFilterFormValues = {
  [FacilitiesFilterFormField.FacilityName]: undefined,
  [FacilitiesFilterFormField.SportName]: undefined,
  [FacilitiesFilterFormField.CompanyId]: undefined,
  [FacilitiesFilterFormField.SplitPayments]: undefined,
};

const { useBreakpoint } = Grid;

export const FacilitiesView = () => {
  const { user } = useUserContext();
  const canCreate = canCreateFacility(user);
  const screens = useBreakpoint();
  const { params, updateParams, resetPage } =
    usePaginationParams<FacilitiesFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by: '-rank',
      ...initialFilterValues,
    });

  const { data, isFetching } = useGetFacilities(params, {
    retry: false,
  });

  const handleFiltersChange = (values: FacilitiesFilterFormValues) => {
    updateParams({
      facility_name: values?.facility_name?.label,
      sport_name: values?.sport_name?.label,
      is_split_payment: values?.is_split_payment,
    });
    resetPage();
  };

  const handleMainFiltersChange = (values: MainFiltersParams) => {
    updateParams({
      company_id: values?.company_id,
    });
    resetPage();
  };

  const formId = 'facilities-filters-form';

  return (
    <>
      <ContentTopBar
        title={t('facilities.heading')}
        renderHeadingContent={
          <MainFilters
            filterTypes={['companyType', 'company']}
            onChange={handleMainFiltersChange}
          />
        }
        renderButtons={
          <>
            {canCreate && (
              <Button
                icon={!screens.xs ? <PlusOutlined /> : undefined}
                href={ROUTES.FACILITIES_CREATE}
              >
                {t('facilities.buttons.addFacility')}
              </Button>
            )}
          </>
        }
      />

      <ContentFilters formId={formId}>
        <FacilitiesFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
          companyId={params.company_id}
        />
      </ContentFilters>

      <FacilitiesTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
