import { PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  Grid,
  Image as IMG,
  Input,
  message,
  Row,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { Modal } from 'components';
import { SponserApiService } from 'features/sponsers/api/sponser-api.service';
import { useUpdateSponser } from 'features/sponsers/use-cases/update-offer-banner';
import { useEffect, useState } from 'react';

const { useBreakpoint } = Grid;

export const EditSponserModal = ({ onClose, id, onSuccess }: any) => {
  const formName = 'edit-sponser-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const { isLoading, mutate } = useUpdateSponser();

  const handleClose = () => {
    onClose();
    document.body.style.overflow = 'visible';
  };

  const initialDetails = async () => {
    try {
      const apiRes = await SponserApiService().getSponserById({
        id: id,
      } as any);

      form.setFieldsValue({
        name: apiRes?.items[0].name,
        name_arabic: apiRes?.items[0].name_arabic,
      });

      // Set file list if there is an image
      if (apiRes?.items[0]?.image) {
        setFileList([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: apiRes?.items[0].image,
          },
        ]);
      }
    } catch (error: any) {
      message.error(error);
    }
  };

  useEffect(() => {
    initialDetails();
  }, []);

  const handleUpdate = async (formData: any) => {
    try {
      await form.validateFields();

      if (!fileList || fileList.length === 0) {
        form.setFields([
          {
            name: 'ImageFile',
            errors: ['Please upload a banner image!'],
          },
        ]);

        return;
      }

      form.setFields([
        {
          name: 'ImageFile',
          errors: [],
        },
      ]);

      const formDataToSend = new FormData();

      formDataToSend.append('id', id);
      formDataToSend.append('Flag', 'Set');
      formDataToSend.append('name', formData?.name);
      formDataToSend.append('name_arabic', formData?.name_arabic);

      if (fileList && fileList.length > 0 && fileList[0].originFileObj) {
        const file: any = fileList[0].originFileObj;
        formDataToSend.append('ImageFile', file);
      }

      mutate(formDataToSend, { onSuccess });
    } catch (error: any) {
      message.error(error);
    }
  };

  const getBase64 = (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob); // Generate preview for local files
    }
    setPreviewImage(file.url || (file.preview as any)); // Use URL if available; otherwise, use preview
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const checkImageDimensions = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const { width, height } = img;
          if (width <= 100 && height <= 100) {
            resolve(true);
          } else {
            message.error(
              `Image dimensions must be minimum 150px (width) x 100px (height). Your image is ${width}px x ${height}px.`,
            );
            resolve(false);
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <Modal
      open
      centered
      className='right-modal cstmmdlright'
      title='Update Sponsor'
      onCancel={handleClose}
      getContainer={false}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      okText={'Update'}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={screens.lg ? '520px' : '100%'}
    >
      <Form
        id={formName}
        name={formName}
        form={form}
        layout='vertical'
        onFinish={handleUpdate}
        onFinishFailed={() => setValidateTrigger(['onChange'])}
        validateTrigger={validateTrigger}
        disabled={isLoading}
      >
        <figure style={{ padding: '15px 5px' }}>
          <Form.Item
            label='Name'
            name='name'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter English Name',
              },
            ]}
          >
            <Input placeholder='Enter English Name' maxLength={54} />
          </Form.Item>

          <Form.Item
            label='Name Ar'
            name='name_arabic'
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter Arabic Name',
              },
            ]}
          >
            <Input placeholder='Enter Arabic Name' maxLength={54} dir={'rtl'} />
          </Form.Item>

          <Row>
            <Col span={12}>
              <Form.Item
                label='Image'
                name='ImageFile'
                help={'Recommend maximum size width: 100px, Height: 100px'}
              >
                <Upload
                  action={undefined}
                  beforeUpload={async (file) => {
                    const isImage = [
                      'image/jpeg',
                      'image/png',
                      'image/jpg',
                    ].includes(file.type);
                    const isSize = file.size <= 25 * 1024;

                    if (!isImage) {
                      message.error(
                        'You can only upload JPG, JPEG, or PNG files!',
                      );
                    }

                    if (!isSize) {
                      message.error(
                        'You can only upload maximum 25 kb image size',
                      );
                    }

                    const isDimension = await checkImageDimensions(file); // Await the result

                    return isImage && isSize && isDimension
                      ? false
                      : Upload.LIST_IGNORE;
                  }}
                  listType='picture-card'
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  multiple
                  maxCount={1}
                  accept='.jpg,.jpeg,.png'
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                {previewImage && (
                  <IMG
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: setPreviewOpen,
                    }}
                    src={previewImage}
                    alt='Preview Image'
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </figure>
      </Form>
    </Modal>
  );
};
