export type Currency = 'KWD';

export enum PaymentMethod {
  Cash = 'CASH',
  Knet = 'KNET',
  Wallet = 'WALLET',
  Credit = 'CREDITCARD',
  Apple = 'APPLEPAY',
  // None=''
}

export enum PaymentStatus {
  Created = 'CREATED',
  Paid = 'PAID',
  Unpaid = 'UNPAID',
}

export type Transaction = {
  id?: string;
  total_cost: number;
  currency: Currency;
  payment_method: PaymentMethod;
  discount: number;
};

export type BookingTransaction = {
  payment_method: PaymentMethod;
  payment_status: PaymentStatus;
  total_cost: number;
  total_cost_with_discount: number;
  discount: number;
  created_at: string;
  ottu_transaction: {
    id: string;
    ref: string | null;
  };
  split_transactionList: any;
  paid_amount: any;
  is_split_payment: boolean;
};
