import { message } from 'antd';
import { ContentTopBar } from 'components';
import dayjs from 'dayjs';
import { Reports } from 'features/wallet/components';
import { useGetReportWalletList } from 'features/wallet/use-cases/get-report-wallet-list';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

export const WalletReportsView = () => {
  const [loading, setLoading] = useState({
    loading1: false,
  });

  const [queryFilters, setQueryFilters] = useState<string | null>(null);

  const { downloadWalletReport } = useGetReportWalletList();

  const handleDate = (date: string | null) => {
    setQueryFilters(date);
  };

  const handleGetReport = async () => {
    if (queryFilters) {
      const payload = {
        to_date: dayjs(queryFilters).format('YYYY-MM-DD'),
      };

      await downloadWalletReport(payload);
    } else {
      message.error('Please select date');
      setLoading({
        ...loading,
        loading1: false,
      });
    }
  };

  const handleWalletReport = async () => {
    setLoading({
      ...loading,
      loading1: true,
    });

    await handleGetReport();
    setLoading({
      ...loading,
      loading1: false,
    });
  };

  return (
    <>
      <ContentTopBar title={'Wallet Report'} />

      <Reports
        handleDate={handleDate}
        handleWalletReport={handleWalletReport}
        loading={loading.loading1}
      />

      <Outlet />
    </>
  );
};
